<template>
  <div id="app" :class="theme">
    <div class="header-container bgc1 row">
      <div class="header-logo pointer">
        <img :src="require(`@/assets/img/logo-light.png`)" width="112" height="32" @click="$router.push('/')"/>
      </div>
      <div class="bit-header-tab">
        <bit-header-tab ref="headerTab" :theme="theme"/>
      </div>
      <div class="bit-header-options">
        <div class="row gap4 fc1 pointer" @click="showUsQrCode=!showUsQrCode">
          <img :src="require(`@/assets/img/icon-case-${theme}.png`)"/>
          <div>{{ this.$t("tab.contactUs") }}</div>
        </div>
        <el-popover
          :popper-class="'language-popover shadow-'+theme"
          placement="bottom-start"
          width="88"
          :visible-arrow="false"
          trigger="hover">
          <img class="pointer popover-img" :src="require(`@/assets/img/icon-language-${theme}.png`)" slot="reference"/>
          <div :class="'language-box pointer '+theme ">
            <div class="hc1" @click="dropdownHandler('zh')">{{ this.$t("tab.zh") }}</div>
            <div class="hc1" @click="dropdownHandler('en')">{{ this.$t("tab.en") }}</div>
          </div>
        </el-popover>
        <img class="pointer" :src="require(`@/assets/img/icon-theme-${theme}.png`)" @click="changeTheme"/>
      </div>
    </div>
    <div class="main-container" ref="mainContainer">
      <router-view @scrollToBottom="scrollToBottom"  @scrollToTop="scrollToTop"/>
    </div>
    <!-- 弹窗 -->
    <div class="mask pointer" v-show="showUsQrCode" @click="showUsQrCode=!showUsQrCode">
      <div class="code-item">
        <div class="fc1">{{ this.$t("tab.contactUs") }}</div>
        <img class="code-item-img" :src="require(`@/assets/img/work-weixin-${theme}.png`)" width="400" height="480"/>
      </div>
    </div>
  </div>
</template>
<script>
import HeaderTab from "@/components/BitHeaderTab.vue";
import BitHeaderTab from "@/components/BitHeaderTab.vue";
export default {
  name: "app",
  components: {
    BitHeaderTab,
    HeaderTab,
  },
  computed:{
    theme: function () {
      let t = this.$store.state.theme
      if(t==null){
        t = localStorage.getItem("theme")
        if(t == null) t = 'dark'
      }
      return t
    },
  },
  data() {
    return {
      showUsQrCode: false,
      language: localStorage.getItem("lang") || "zh",
      loginDia: false,
      accessToken: localStorage.getItem("accessToken"),
      username: "",
      showLanguage: false
    };
  },
  mounted() {
    if (localStorage.getItem("userInfo")) {
      this.username = JSON.parse(localStorage.getItem("userInfo")).name;
    }
    this.$bus.$on("showUsQrCode", ()=>{
      this.showUsQrCode = !this.showUsQrCode
    });
  },
  beforeDestroy() {
    this.$bus.$off("showUsQrCode");
  },
  methods: {
    changeTheme(){
      localStorage.setItem("theme", this.theme=="dark"?"light":"dark")
      this.$store.commit("updateTheme",this.theme=="dark"?"light":"dark");
    },
    dropdownHandler(language) {
      localStorage.setItem("lang", language);
      this.$i18n.locale = language;
      this.$bus.$emit("resetCaseForm")
    },
    scrollToBottom() {
      var container = this.$refs.mainContainer;
      container.scrollTop = container.scrollHeight;
    },
    scrollToTop() {
      var container = this.$refs.mainContainer;
      container.scrollTop = 0;
    },
    wxLoginSuccess() {
      this.loginDia = false;
      this.accessToken = localStorage.getItem("accessToken");
      this.username = JSON.parse(localStorage.getItem("userInfo")).name;
      // 设置登录状态
      localStorage.setItem("isLogin",1);
    },
  }
};
</script>
<style lang="scss" scoped>
@import "~@/assets/css/style.css";
#app {
  width: 100%;
  min-width: 1440px;
  overflow-x: auto;
  overflow-y:scroll;
  // height: 100%;
  display: flex;
  flex-direction: column;
  .header-container {
    padding: 0 40px;
    height: 64px;
    width: 100%;
    box-sizing: border-box;
    position: fixed;
    z-index: 333;
    .bit-header-tab {
      margin:0 40px;
    }
    .bit-header-options {
      position: absolute;
      right: 40px;
      display: flex;
      gap: 40px;
      align-items: center;
      img{
        width: 24px;
        height: 24px;
      }
      .popover-img{
        position: relative;
        top: 2.5px;
      }
    }
  }
  .main-container {
    flex: 1;
    display: flex;
    min-width: 1280px;
    margin-top: 64px;
  }
  .mask{
    width: 100%;
    height: 100%;
    position: fixed;
    z-index: 1000;
    top: 0;
    left: 0;
  }
  .code-item{
    position: absolute;
    top: calc(50vh - 240px);
    left: calc(50% - 200px);
    width: 400px;
    height: 480px;
    z-index: 1001;
    div{
      width: 400px;
      height: 32px;
      text-align: center;
      position: absolute;
      top: 80px;
      font-size: 24px;
      font-weight: 500;
      line-height: 32px;
    }
  }
  /deep/.el-dropdown.dropdown-img {
    width: 22px;
    height: 21px;
    margin-right: 8px;
  }
}
.language-box{
  width: 88px !important;
  height: 88px !important;
  box-sizing: border-box;
  padding: 4px 0;
  div{
    height: 40px;
    text-align: left;
    line-height: 40px;
    font-size: 14px;
    padding-left: 16px;
  }
}
</style>
