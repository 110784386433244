import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import bus from "@/utils/bus";
import VueI18n from "vue-i18n";
import ElementUI from "element-ui";
import "./assets/theme/index.css";
import './assets/css/element-variables.scss';

import enLocale from "element-ui/lib/locale/lang/en";
import zhLocale from "element-ui/lib/locale/lang/zh-CN";
import ElementLocale from "element-ui/lib/locale";


Vue.config.productionTip = false;

Vue.use(VueI18n);
Vue.use(ElementUI);

const messages = {
  en: {
    message: "hello",
    login:"login",
    undoMsg: "Coming soon",
    tabs: [
      {
        name:"Home",
        path:"/",
      },
      {
        name:"Solution",
        children:[
          {
            name: "Zhongkui Data Platform",
            path:"/product/zktrace",
          },
          {
            name: "Asset recovery",
            path:"/product/assetRecovery",
          },
          {
            name: "Security audit services",
            path:"",
          }
        ]
      },
      {
        name:"News",
        path:"",
      },
      {
        name:"About Us",
        path:"/bitjungle",
      },
    ],
    tab: {
      case: "Assist",
      contactUs: "Consult Us",
      zh: "简体中文",
      en: "English"
    },
    home: {
      section1: {
        title: "Blockchain Security Solutions",
        subTitle: "Industry-leading blockchain data and security service provider",
        describe: "",
        contactUs: "Assist",
        caseApply:""
      },
      section2: {
        title: "Zhongkui Data Platform",
        subTitle: "",
        info1: {
          title: "Billions of  address entity labels",
          subTitle: "Accurately identify address"
        },
        info2: {
          title: "Transaction tracking",
          subTitle: "Visualization of cross-chain and multichain transaction"
        },
        info3: {
          title: "Full-cycle asset recovery service",
          subTitle: "Tools for the entire process to asset recovery"
        },
        info4: {
          title: "Monitor address",
          subTitle: "7*24 hours monitoring and warning"
        },
        btn:{
          title: "Use Zhong Kui to start the investigation",
          subTitle: "Enter"
        }
      },
      section3: {
        title: "Asset recovery",
        subTitle: "",
        info1: {
          title: "Professional investigation team",
          subTitle: "A team of experts in incident investigation"
        },
        info2: {
          title: "Rich experience in asset recovery",
          subTitle: "Successfully recovered over $100 million in assets"
        },
        info3: {
          title: "Close collaboration with the judiciary",
          subTitle: "Experience in international law enforcement cooperation"
        }
      },
      section4: {
        title: "Security audit services",
        subTitle: "",
        info1: {
          title: "Comprehensive audit solution",
          subTitle: "Covers web2 and web3 security needs"
        },
        info2: {
          title: "Professional emergency incident response",
          subTitle: "Quickly mitigate the impact of exchange and DeFi security incidents"
        },
        info3: {
          title: "One-stop audit service",
          subTitle: "From code review to report release"
        }
      },
      section5: {
        title: "Professional team at your service",
        subTitle: "",
        info1: {
          title: "Industry-leading asset recovery performance",
          subTitle: ""
        },
        info2: {
          title: "Industry senior security expert",
          subTitle: ""
        },
        info3: {
          title: "Billion-level tag + AI big data platform",
          subTitle: ""
        },
        info4: {
          title: "Customized solutions",
          subTitle: ""
        }
      },

      section6: {
        info1: {
          title: "160",
          unit: "M",
          subTitle: "USD has been recovered"
        },
        info2: {
          title: "65",
          unit: "%",
          subTitle: "Success rate in hacker"
        },
        info3: {
          title: "90",
          unit: "%",
          subTitle: "Success rate in inside job"
        },
        info4: {
          title: "60",
          unit: "%",
          subTitle: "Major case market share"
        }
      },

      section7: {
        title: "News",
      },

      section8: {
        title: "Get the latest news",
        subTitle: "Contact BitJungle to be the first to receive the latest news"
      },

      section9: {
        prod:{
          title: "Solution",
          sub1: "Zhongkui Data Platform",
          sub2: "Asset recovery",
          sub3: "Security audit services",
        },
        case:{
          title: "News",
          sub1: "Case information",
        },
        us:{
          title: "About Us",
          sub1: "About",
        },
        logo: "Blockchain Security Solutions"
      },

      section10: {
        version: "@2018-2025 bitjungle.io ALL Rights Reserved"
      }
    },
    trace: {
      banner: {
        title: "Asset recovery",
        subTitle: "A professional team with 8 years of experience, having recovered billions of assets to date.",
        contactUs: "Contact US",
      },
      services_flow: {
        title: "Service Process",
        info1: {
          title: "Case Address Analysis",
          subTitle: "Case discussion+ AI big data platform"
        },
        info2: {
          title: "Evidence Collection",
          subTitle: "Analysis, traceability, tracking, and more."
        },
        info3: {
          title: "Assist in the investigation",
          subTitle: "Cooperate with relevant departments"
        },
        info4: {
          title: "Post-payment fee",
          subTitle: "Service before payment"
        }
      },
      services_content: {
        title: "Service Flow",
        info1: {
          title: "Transaction Analysis",
          subTitle: "Analyze, organize, and identify suspicious transaction records"
        },
        info2: {
          title: "Monitoring and tracking",
          subTitle: "Track fund movements and monitor to push alerts for anomalies"
        },
        info3: {
          title: "Tracing and forensics",
          subTitle: "Confirm the evidence and clarify the subject of the investigation"
        },
        info4: {
          title: "Assist relevant departments",
          subTitle: "Cooperate with freezing, investigation, and evidence collection."
        },
        info5: {
          title: "Customized service",
          subTitle: "Provide customized technical and service support to help overcome difficulties."
        },
        info6: {
          title: "Virtual Asset Disposal",
          subTitle: "Compliance and secure disposal of virtual currencies."
        },
      },
      advantages: {
        title: "Core Advantage",
        info1: {
          title: "Professional Team",
          subTitle: "The team of case investigation experts is at your service."
        },
        info2: {
          title: "Technical Support",
          subTitle: "AI big data platform and Data Engineering Support"
        },
        info3: {
          title: "Deep cooperation",
          subTitle: "Experienced in case-solving cooperation with judicial authorities"
        },
      },
      cooperation: {
        title: "Cooperation",
        info1: {
          title: "Strategic Cooperation",
          partner1: "Qingdao Municipal Bureau and its sub-bureaus",
          partner2:"Shenzhen Municipal Bureau",
          partner3:"Binzhou, Shandong",
          partner4:"Dezhou, Shandong"
        },
        info2: {
          title: "Strategic Cooperation",
          partner1: "Criminal Police of Qingdao Public Security Bureau",
          partner2: "Cybersecurity Bureau of Binzhou City, Shandong Province",
          partner3:"",
          partner4:""
        },
      },
    },
    zhongkui: {
      banner: {
        title: "Zhongkui Data Platform",
        subTitle: "Cross-chain analysis, monitoring, traceability, and visual tracking of crypto currency",
        contactUs: "Free Trial",
      },
      advantages: {
        title: "Core Advantage",
        info1: {
          title: "AI Data Analysis",
          subTitle: "Data visualization for efficiently identifying suspicious leads."
        },
        info2: {
          title: "Massive Tag Coverage",
          subTitle: "Billions of address tags to pinpoint high-risk deals"
        },
        info3: {
          title: "Monitoring Alert",
          subTitle: "Real-time alerts for suspicious addresses, customizable."
        },
        info4: {
          title: "Mainstream Blockchain",
          subTitle: "Mainstream blockchain data covers 90% of major cases"
        },
        info5: {
          title: "Cross-chain Display",
          subTitle: "Break down blockchain barriers and easily access the complete relationship map"
        },
        info6: {
          title: "User Experience",
          subTitle: "Integrating real cases for efficient data presentation."
        },
      },
      product: {
        title: "Product Features",
        list:[
          {
            img: `/img/zhongkui-1.jpg`,
            title: "Graph Creation",
            subTitle: "Batch import addresses and transactions, visualize the graph."
          },
          {
            img: `/img/zhongkui-2.jpg`,
            title: "Edit Operation",
            subTitle: "Practical design for easy editing and integration of graph content."
          },
          {
            img: `/img/zhongkui-3.jpg`,
            title: "Case Management",
            subTitle: "Custom folders, download and share cases for efficient display."
          },
          {
            img: `/img/zhongkui-4.jpg`,
            title: "Risk Assessment",
            subTitle: "Assess risks and issue early warnings"
          },
          {
            img: `/img/zhongkui-5.jpg`,
            title: "Address Monitoring",
            subTitle: "Monitor addresses and access key information."
          },
          {
            img: `/img/zhongkui-6.jpg`,
            title: "Monitor Alerts",
            subTitle: "Set up custom notifications, and manage info flexibly."
          }
        ]
      },
      provide: {
        title: "Provide",
        info1: {
          title: "Zhongkui Data Platform",
          subTitle: "Use Zhong Kui to solve major cases",
          btn: "Free Trial"
        },
        info2: {
          title: "Ordinary user",
          subTitle: "Monitor suspicious addresses, track fund transfers, and get alerts.",
        },
        info3: {
          title: "professional user",
          subTitle: "Offer professional tools to simplify usage and enable swift case solving.",
        },
      },
    },
    bitjungle: {
      banner: {
        title: "Bit Jungle",
        subTitle: "Ethical, socially responsible, and a pioneer in blockchain security."
      },
      intro: {
        title: "Company Profile",
        p: [
          "Bit Jungle, founded in 2018, is a globally recognized leader in Web3 security, providing end-to-end solutions for blockchain security incidents.",
          "Powered by AI and advanced analytics, our proprietary Zhongkui system delivers high-efficiency on-chain forensics, cross-chain analysis, and address monitoring, covering over 200M entity labels to rapidly detect and trace suspicious activities.",
          "We collaborate closely with global law enforcement agencies to provide tailored security solutions."
        ]
      },
      time: {
        title: "Development History",
        list: [
          {
            time: "2024",
            events: [
              "Zhongkui System 2.0 launched",
              "Handle major supervision cases",
              "Forge in-depth cooperation with Shenzhen, Dezhou, and others"
            ]
          },
          {
            time: "2023",
            events: [
              "Form strategic partnership with Qingdao police",
              "Big data modeling and inference",
              "Engage in external investments"
            ]
          },
          {
            time: "2022",
            events: [
              "Breakthroughs in cross-chain and mix-coin technology",
              "Handle cases for exchanges and DeFi",
              "Engineering services for efficiency"
            ]
          },
          {
            time: "2021",
            events: [
              "Handle billion-dollar blockchain cases",
              "Gotrack system (predecessor of Zhongkui system) for internal use",
              "Train police in blockchain security across regions"
            ]
          },
          {
            time: "2018",
            events: [
              "Solving the first domestic Bitcoin theft case",
              "Offer digital recovery services",
            ]
          },
        ]
      },
      target: {
        title: "Mission and Vision",
        info1:{
          title: "Mission",
          subTitle: "Leading sustainable development in blockchain security."
        },
        info2: {
          title: "Vision",
          subTitle: "Become the most trusted security solution provider."
        },
        info3: {
          title: "Values",
          subTitle: "Act with integrity and social responsibility."
        },
      },
      location:{
        title: "Office Location",
        location: [
          {
            img: require(`@/assets/img/location-qingdao.png`),
            title: "Qingdao",
            address: "Address: Licang District, Qingdao."
          },
          {
            img: require(`@/assets/img/location-shenzhen.png`),
            title: "Shenzhen",
            address: "Address: Futian District, Shenzhen."
          },
        ]
      }
    },
    case: {
      header: {
        title: "Assist",
        subTitle: "Please fill in your information, and after Bit Jungle assesses it, we will provide you with free case evaluation assistance services.",
        weixin: "WeChat",
        tiktok: "Tik Tok",
        redbook: "REDnote",
        warnMsg: "If your digital assets is stolen, Bitjungle will provide free case assessment assistance. After you submit the relevant information, we will promptly analyze your case and contact you through the provided contact details with any updates."
      },
      notify: {
        title: "Submit successfully",
        content: "If the case is accepted, we will contact you within 3 working days."
      },
      form:{
        requiredMsg: "* indicates a required field.",
        successMsg: "Submitted successfully. We will contact you soon",
        errorMsg: "Submission failed, please re-enter the information",
        contact: {
          label:"Contact information",
          placeholder: "Please enter your email/Telegram/WeChat/phone/other.",
          requiredWarnMsg: "Please enter"
        },
        storage: {
          label: "Digital Asset Storage",
          placeholder: "Please enter the platform you use.",
          requiredWarnMsg: "Please select",
          data:[
            'Mobile wallet',
            'Browser Wallet',
            'Hardware Wallets',
            'Crypto exchange',
            'Other'
          ],
          other: 'Other'
        },
        incidents: {
          label: "Face issues",
          placeholder: "Please enter your case types",
          requiredWarnMsg: "Please select",
          data:[
            'Fraud',
            'Phishing',
            'Hacking',
            'Ransomware',
            'Other'
          ],
          other: 'Other'
        },
        loss_usd: {
          label: "Loss Amount (USD)",
          placeholder: "Please enter the loss amount.",
          requiredWarnMsg: "Please enter"
        },
        loss_addr: {
          label: "Stolen Address/Transaction Hash",
          placeholder: "Please enter the address/hash.",
          requiredWarnMsg: "Please enter"
        },
        remark: {
          label: "Other",
          placeholder: "Please enter"
        },
        commitBtn: 'Submit'
      },
    },
    ...enLocale,
  },
  zh: {
    message: "你好",
    login:"登录",
    undoMsg: "敬请期待",
    tabs: [
      {
        name:"首页",
        path:"/",
      },
      {
        name:"产品服务",
        children:[
          {
            name:"数字资产追回",
            path:"/product/assetRecovery",
          },
          {
            name:"钟馗大数据平台",
            path:"/product/zktrace",
          },
          {
            name:"安全审计服务",
            path:"",
          }
        ]
      },
      {
        name:"案例新闻",
        path:"",
      },
      {
        name:"关于我们",
        path:"/bitjungle",
      },
    ],
    tab: {
      case: "案件援助",
      contactUs: "联系我们",
      zh: "简体中文",
      en: "English"
    },
    home: {
      section1: {
        title: "守护区块链生态安全",
        subTitle: "业界领先的链上数据和安全服务提供商",
        describe: "数字资产溯源｜安全审计服务｜链上追踪",
        contactUs: "案件咨询",
        caseApply:"案件提交"
      },
      section2: {
        title: "钟馗大数据平台",
        subTitle: "还原事件真相、找到嫌疑人、挽回被盗数字资产",
        info1: {
          title: "亿级标签覆盖",
          subTitle: "精准识别交易地址身份"
        },
        info2: {
          title: "交易链路追踪",
          subTitle: "跨链多币资金流向可视化"
        },
        info3: {
          title: "一站式追回能力",
          subTitle: "线索发掘到资产挽回全流程工具"
        },
        info4: {
          title: "地址交易监控",
          subTitle: "7*24小时监控和预警"
        },
        btn:{
          title: "用钟馗 破大案",
          subTitle: "立即体验"
        }
      },
      section3: {
        title: "数字资产追回",
        subTitle: "还原事件真相、找到嫌疑人、挽回被盗数字资产",
        info1: {
          title: "专业破案团队",
          subTitle: "行业资深安全专家团队为您服务"
        },
        info2: {
          title: "追回经验丰富",
          subTitle: "成功追回10亿被盗资产"
        },
        info3: {
          title: "执法深度合作",
          subTitle: "同多地公安合作熟悉案件侦破全流程"
        }
      },
      section4: {
        title: "安全审计服务",
        subTitle: "还原事件真相、找到嫌疑人、挽回被盗数字资产",
        info1: {
          title: "全方位审计解决方案",
          subTitle: "覆盖传统与区块链安全需求"
        },
        info2: {
          title: "专业应急响应",
          subTitle: "迅速减轻交易所与DeFi安全事件影响"
        },
        info3: {
          title: "一站式审计服务",
          subTitle: "从代码审核到报告出具"
        }
      },
      section5: {
        title: "专业团队为您服务",
        subTitle: "还原事件真相、找到嫌疑人、挽回被盗数字资产",
        info1: {
          title: "业界领先的资产追回表现",
          subTitle: ""
        },
        info2: {
          title: "行业资深安全专家",
          subTitle: ""
        },
        info3: {
          title: "亿级标签库+AI大数据平台",
          subTitle: ""
        },
        info4: {
          title: "定制化解决方案",
          subTitle: ""
        }
      },

      section6: {
        info1: {
          title: "1.6",
          unit: "亿",
          subTitle: "累计挽回美金"
        },
        info2: {
          title: "65",
          unit: "%",
          subTitle: "外部黑客破案率"
        },
        info3: {
          title: "90",
          unit: "%",
          subTitle: "内部作案破案率"
        },
        info4: {
          title: "60",
          unit: "%",
          subTitle: "重大案件破获率（行业第一）"
        }
      },

      section7: {
        title: "成功案例与热点资讯",
        info1: {
          title: "1.最新解读：两高将虚拟资产交易纳入洗钱方式，谨慎…",
          subTitle: "2024年8月19日，最高人民法院与最高人民检察院联合发布了最新的司法解释，将虚拟资产交易明确列为洗钱手段之一…"
        },
      },

      section8: {
        title: "获取最新动态",
        subTitle: "与比特丛林建立联系，抢先获取最新动态"
      },

      section9: {
        prod:{
          title: "产品服务",
          sub1: "钟馗大数据平台",
          sub2: "数字资产追回",
          sub3: "报案文书生成",
        },
        case:{
          title: "成功案例",
          sub1: "成功案例",
          sub2: "视频支持",
        },
        us:{
          title: "关于我们",
          sub1: "公司简介",
        },
        logo: "区块链生态安全守护者",
      },

      section10: {
        version: "@2018-2025 bitjungle.io ALL Rights Reserved"
      }
    },
    trace: {
      banner: {
        title: "数字资产追回",
        subTitle: "专业破案团队，8年沉淀已累计追回数亿资产",
        contactUs: "案件咨询",
      },
      results: {
        info1: {
          title: "1.6",
          unit: "亿",
          subTitle: "累计挽回美金"
        },
        info2: {
          title: "65",
          unit: "%",
          subTitle: "外部黑客破案率"
        },
        info3: {
          title: "90",
          unit: "%",
          subTitle: "内部作案破案率"
        },
        info4: {
          title: "60",
          unit: "%",
          subTitle: "重大案件破获率（行业第一）"
        }
      },
      services_flow: {
        title: "服务流程",
        info1: {
          title: "案件地址分析",
          subTitle: "案情沟通+AI大数据平台支持"
        },
        info2: {
          title: "证据搜集整理",
          subTitle: "专业安全团队分析、溯源、追踪等"
        },
        info3: {
          title: "报案协助侦查",
          subTitle: "配合相关部门破获案件"
        },
        info4: {
          title: "后付费模式",
          subTitle: "数字资产找回后，再支付费用"
        }
      },
      services_content: {
        title: "服务内容",
        info1: {
          title: "交易分析",
          subTitle: "整理分析与被盗的地址相关的交易记录，明确可疑实体"
        },
        info2: {
          title: "监控追踪",
          subTitle: "追踪链上资金动向。实时监控案件可疑地址，推送异常告警信息"
        },
        info3: {
          title: "溯源取证",
          subTitle: "确定涉案证据链，明确锁定调查主体，提供报案证据"
        },
        info4: {
          title: "协助有关部门",
          subTitle: "配合相关部门工作，进行冻结、侦查、取证工作"
        },
        info5: {
          title: "定制服务",
          subTitle: "提供定制化技术与服务支持，协助配合攻破案件特殊性难点"
        },
        info6: {
          title: "虚拟资产处置",
          subTitle: "虚拟货币的合规安全处置"
        },
      },
      advantages: {
        title: "核心优势",
        info1: {
          title: "专业破案团队",
          subTitle: "案件调查专家团队为您服务"
        },
        info2: {
          title: "技术支持",
          subTitle: "自研AI大数据平台+数据工程师支持"
        },
        info3: {
          title: "司法机关深度合作",
          subTitle: "与司法机关破案合作经验丰富"
        },
      },
      cooperation: {
        title: "合作支持",
        info1: {
          title: "深度合作",
          partner1: "青岛市市局及各分局",
          partner2:"山东滨州",
          partner3:"深圳市局",
          partner4:"山东德州"
        },
        info2: {
          title: "战略合作",
          partner1: "青岛市公安局刑警",
          partner2: "山东省滨州市网安",
          partner3:"",
          partner4:""
        },
      },
    },
    zhongkui: {
      banner: {
        title: "钟馗AI大数据平台",
        subTitle: "区块链数据跨链分析、监控溯源、可视化追踪非法资金",
        contactUs: "免费试用",
      },
      advantages: {
        title: "核心优势",
        info1: {
          title: "AI数据分析",
          subTitle: "数据可视化，高效锁定可疑线索"
        },
        info2: {
          title: "海量标签覆盖",
          subTitle: "数亿地址标签，精准识别高危交易"
        },
        info3: {
          title: "监控预警",
          subTitle: "异常地址实时监控，自定义预警方式"
        },
        info4: {
          title: "主流公链支持",
          subTitle: "常见主流公链数据，覆盖90%大型案件"
        },
        info5: {
          title: "跨链展示",
          subTitle: "打通公链壁垒，轻松获取完整关系图谱"
        },
        info6: {
          title: "用户体验",
          subTitle: "结合真实案件，高效展示数据信息"
        },
      },
      product: {
        title: "产品功能",
        list:[
          {
            img: `/img/zhongkui-1.jpg`,
            title: "图谱创建",
            subTitle: "常见主流公链数据，覆盖90%大型案件"
          },
          {
            img: `/img/zhongkui-2.jpg`,
            title: "操作编辑",
            subTitle: "精美实用的结构功能，轻松完成操作编辑，整合图谱内容"
          },
          {
            img: `/img/zhongkui-3.jpg`,
            title: "案件管理",
            subTitle: "自定义文件夹，下载分享图谱案例，以便更高效地展示信息"
          },
          {
            img: `/img/zhongkui-4.jpg`,
            title: "风险评估",
            subTitle: "评估目标地址风险，检测告知潜在风险信息。提前预警风险"
          },
          {
            img: `/img/zhongkui-5.jpg`,
            title: "地址监控",
            subTitle: "监控可疑地址，查看监控任务详情，轻松掌握关键节点"
          },
          {
            img: `/img/zhongkui-6.jpg`,
            title: "监控通知",
            subTitle: "实时接收通知，可自定义编辑通知方式，灵活智能接收信息。"
          }
        ]
      },
      provide: {
        title: "为您提供",
        info1: {
          title: "钟馗AI大数据平台",
          subTitle: "用钟馗 破大案",
          btn: "免费试用"
        },
        info2: {
          title: "普通用户",
          subTitle: "实时监控可疑地址，准确追踪资金转移情况，及时掌握告警通知",
        },
        info3: {
          title: "专业用户",
          subTitle: "提供专业工具，降低使用门槛，满足精准快速破获案件的需求",
        },
      },
    },
    bitjungle: {
      banner: {
        title: "关于我们",
        subTitle: "永不作恶、具备社会责任感，区块链安全行业开拓者"
      },
      intro: {
        title: "公司简介",
        p: [
          "比特丛林（Bit Jungle）是全球领先的Web3安全解决方案提供商，成立于2018年，专注于为客户提供全面的区块链安全服务，包括事件分析、溯源调查、证据支持、资产冻结协助和安全审计等。",
          "我们自主研发的钟馗大数据溯源系统，结合AI和大数据技术，支持高效链上溯源、跨链分析与地址监控，覆盖超过2亿实体标签，能够快速识别和追踪可疑活动。",
          "我们与全球司法机关紧密合作，为客户量身定制解决方案。"
        ]
      },
      time: {
        title: "发展历程",
        list: [
          {
            time: "2024",
            events: [
              "钟馗系统2.0版本上线",
              "承接大型督办案件",
              "与深圳市、山东德州市等地达成深度合作"
            ]
          },
          {
            time: "2023",
            events: [
              "与青岛刑警达成战略合作",
              "大数据算法模型推演",
              "开展对外投资业务"
            ]
          },
          {
            time: "2022",
            events: [
              "跨链混币等的技术突破",
              "承接多家交易所、DEFI案件",
              "工程化服务，业务效率提升"
            ]
          },
          {
            time: "2021",
            events: [
              "承接亿万级区块链犯罪案件",
              "Gotrack系统(钟馗系统前身)内部投入使用",
              "为多地警方提供区块链安全培训"
            ]
          },
          {
            time: "2018",
            events: [
              "破获国内首例比特币盗窃案",
              "开启数字资产追回服务",
            ]
          },
        ]
      },
      target: {
        title: "使命及愿景",
        info1:{
          title: "使命",
          subTitle: "引领区块链安全行业可持续发展"
        },
        info2: {
          title: "愿景",
          subTitle: "成为最值得信赖的安全解决方案服务商"
        },
        info3: {
          title: "价值观",
          subTitle: "永不作恶，具备社会责任感"
        },
      },
      location:{
        title: "办公地点",
        location: [
          {
            img: require(`@/assets/img/location-qingdao.png`),
            title: "中国青岛",
            address: "地址：青岛市李沧区"
          },
          {
            img: require(`@/assets/img/location-shenzhen.png`),
            title: "中国深圳",
            address: "地址：深圳市福田区"
          },
        ]
      }
    },
    case: {
      header: {
        title: "案件咨询",
        subTitle: "请填您的信息，比特丛林通过评估后，将为您提供免费案件评估援助服务",
        weixin: "微信公众号",
        tiktok: "抖音",
        redbook: "小红书",
        warnMsg: "如果您的加密货币被盗，Bitjungle 将提供免费的案件评估援助服务。在您提交相关信息之后我们会尽快分析您的案件，并在有最新进展时，通过您提供的联系方式与您联系。"
      },
      notify: {
        title: "提交成功",
        content: "如案件被受理，我们将在3个工作日内联系您"
      },
      form:{
        requiredMsg: "* 表示必填",
        successMsg: "提交成功，请您耐心等待我们将尽快与您取得联系",
        errorMsg: "提交失败，请重新输入信息",
        contact: {
          label:"联系方式",
          placeholder: "请输入邮件/Telegram/微信/电话/其他",
          requiredWarnMsg: "请输入"
        },
        storage: {
          label: "数字资产储存方式",
          placeholder: "请输入其他平台类型",
          requiredWarnMsg: "请选择",
          data:[
            '手机钱包',
            '浏览器钱包',
            '硬件钱包',
            '交易所',
            '其他'
          ],
        },
        incidents: {
          label: "可能遭遇",
          placeholder: "请输入其他案件类型",
          requiredWarnMsg: "请选择",
          data:[
            '网络诈骗',
            '网络钓鱼',
            '黑客盗取',
            '勒索攻击',
            '其他'
          ],
        },
        loss_usd: {
          label: "损失金额（美元）",
          placeholder: "请输入损失金额",
          requiredWarnMsg: "请输入"
        },
        loss_addr: {
          label: "被盗地址/交易哈希",
          placeholder: "请输入地址/哈希",
          requiredWarnMsg: "请输入"
        },
        remark: {
          label: "其他",
          placeholder: "请输入补充说明"
        },
        commitBtn: '提交'
      },
    },
    ...zhLocale,
  },
};
const i18n = new VueI18n({
  locale: localStorage.getItem("lang")||"zh", // set locale
  messages, // set locale messages
});

ElementLocale.i18n((key, value) => i18n.t(key, value));

router.beforeEach((to, from, next)=>{
  // const accessToken = localStorage.getItem("accessToken");
  // if(!accessToken && to.path!="/"){
  //   next({ path: '/' })
  // }else{
    next()
  // }
})
Vue.prototype.$bus = bus;
new Vue({
  router,
  store,
  render: (h) => h(App),
  i18n,
}).$mount("#app");
