import Vue from "vue";
import VueRouter from "vue-router";
import store from "../store";

Vue.use(VueRouter);

const routes = [
  {
    path: '*',
    name: '',
    redirect: '/'
  },
  {
    path: "/",
    name: "home",
    component: () => import("../views/home/index.vue"),
  },
  {
    path: "/product/assetRecovery",
    name: "assetRecovery",
    component: () => import("../views/assetRecovery/index.vue"),
  },
  {
    path: "/product/zktrace",
    name: "zhongkui",
    component: () => import("../views/zhongkui/index.vue"),
  },
  {
    path: "/bitjungle",
    name: "bitjungle",
    component: () => import("../views/bitjungle/index.vue"),
  },
  // {
  //   path: "/chartList",
  //   name: "chartList",
  //   component: () => import("../views/relation/chartlist.vue"),
  // },
  // {
  //   path: "/relationChart",
  //   name: "relationChart",
  //   component: () => import("../views/relation/relationchart.vue"),
  // },
  // {
  //   path: "/aboutus",
  //   name: "aboutus",
  //   component: () => import("../views/aboutus/index.vue"),
  // },
  {
    path: "/home",
    name: "home",
    component: () => import("../views/home/index.vue"),
  },
  // {
  //   path: "/property",
  //   name: "property",
  //   component: () => import("../views/property/index.vue"),
  // },
  // {
  //   path: "/login",
  //   name: "login",
  //   component: () => import("../views/login/index.vue"),
  // },
  // {
  //   path: "/register",
  //   name: "register",
  //   component: () => import("../views/login/register.vue"),
  // },
  // {
  //   path: "/protocol",
  //   name: "protocol",
  //   component: () => import("../views/protocol/service.vue"),
  // },
  // {
  //   path: "/secret",
  //   name: "secret",
  //   component: () => import("../views/protocol/secret.vue"),
  // },
  {
    path: "/assist",
    name: "assist",
    component: () => import("../views/caseApply/index.vue"),
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    } else {
      return { x: 0, y: 0 };
    }
  },
});
router.beforeEach((to,from,next) =>{
  if(to.name == ""){
    store.commit("updateHeaderTab",0)
  }
  else if(to.name == "relationChart"){
    store.commit("updateHeaderTab",1)
  }
  next()
})
export default router;
